.button-style {
    white-space: nowrap;
    border-radius: 5px;
}

.nav-item {
    width: max-content;
}

.flex-style {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: center;
    gap: 5px;
}

.navbar-style {
flex-direction: row-reverse;
}

.container {
    align-items: flex-start;
}