#modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
  width: 70%;
  margin: 0px auto;
}
#modalPassword {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
  width: 70%;
  margin: 0px auto;
}

@media screen and (min-width: 840px) {
  #modal {
    width: 40%;
    height: 50%;
    margin: 0px auto;
  }
  #modalPassword {
    width: 50%;
    height: 70%;
    margin: 0px auto;
  }
}

@media screen and (min-width: 1150px) {
  #modal {
    width: 30%;
  }
  #modalPassword {
    width: 30%;
    height: 70%;
  }
}
