.card_grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

@media screen and (min-width: 1000px) {
  .card_grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
li:hover {
  color: darksalmon;
  cursor: pointer;
}
/* li {
  width: 100%;
} */
.top-bar {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.top-bar-category {
  width: 30%;
  margin: 10px;
  float: left;
}
.top-bar-search {
  width: 80%;
  margin: 10px;
}
