.ant-form-item-label {
    text-align: center;
}

/* .left-aligned-dropdown .ant-select-dropdown-menu {
    text-align: left;
} */
.ant-select-selection-item{
    text-align: left;
}
/* custom-antd.css */
.ant-btn-primary {
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.centered-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.text-left {
    margin-left: 17vw;
}


.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    position: relative;
    padding: 0;
    overflow-wrap: break-word;
}

/* .ant-pagination {
    width: 2vw;
    margin-left: auto;
    display: flex;
    margin-right: 10%;
    justify-content: flex-end;
} */

.container {
    max-width: 80vw;
    margin: auto;
}

.cardBlock {
    border: 1px solid black;
    width: 100%;

}

.titleButton {
    width: 54vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px;
}

.dataContent {

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
}

.titleBlock {
        display: flex;
        flex-direction: row;
}

/* .rev-pagination-wrap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
} */